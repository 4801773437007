import React from 'react';
import {
	GeneralShortcutListener,
	NEXT_COMMENT_SHORTCUT,
	PREV_COMMENT_SHORTCUT,
} from '@confluence/shortcuts';

export const ViewInlineCommentShortcutListener = () => {
	const navigateToNextComment = () => {};
	const navigateToPrevComment = () => {};

	return (
		<>
			<GeneralShortcutListener
				accelerator={NEXT_COMMENT_SHORTCUT}
				listener={navigateToPrevComment}
			/>
			<GeneralShortcutListener
				accelerator={PREV_COMMENT_SHORTCUT}
				listener={navigateToNextComment}
			/>
		</>
	);
};
